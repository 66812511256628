@import '@styles/basic';

.container {
  border: 1px solid var(--neutral200);
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 24px;
  @include mq($until: tablet) {
    margin-bottom: 32px;
  }
}

.credentialsContainer {
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;

  input {
    width: 100%;
  }
}
